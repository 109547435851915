
	import { Component, Prop } from "vue-property-decorator";

	import { now } from "@common/date";
	import Base from "../Base";
	import Record from "./components/Record.vue";
	import PhotoModal from "./modal/PhotoModal.vue";
	import type { KioskItemIndexModel } from "@/models/Item";

	@Component({ components: { Record, PhotoModal } })
	export default class extends Base {
		@Prop() public id!: string;

		$refs!: {
			modal: PhotoModal,
		};

		protected data: KioskItemIndexModel[] | null = null;

		protected get title(): string {
			if(!this.data) return "";
			return this.data[0].MerchantKiosk.Name;
		}

		beforeMount(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			try {
				this.data = await post<KioskItemIndexModel[]>("/api/Item/Detail", { Id: this.id });
				this.update = now();
			} catch(e) {
				this.data = null;
				throw e;
			}
		}

		protected photo(k: KioskItemIndexModel): void {
			this.$refs.modal.show(k.KioskItem!.Item.Photo);
		}

		protected change(): void {
			this.$router.push("/item/edit/" + this.id);
		}
	}


	import { Component, Vue } from 'vue-property-decorator';

	import * as bootstrap from 'bootstrap';

	@Component
	export default class extends Vue {
		private modal!: bootstrap.Modal;
		protected src: string = "";

		mounted(): void {
			this.modal = new bootstrap.Modal(this.$el);
		}

		public show(src: string): void {
			this.src = "/FileSystem/" + src;
			this.modal.show();
		}
	}


	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import { TEN_SECONDS } from "@common/communication";
	import { LeapaItemTypeOptions } from "@/models/Item";
	import { resetState as reportIndexReset } from "../report/Index.vue";
	import { resetState } from "./Index.vue";

	import type { KioskItemEditModel } from "@/models/Item";

	@Component
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		protected data: KioskItemEditModel | null = null;

		@Prop() public mkId!: string;
		@Prop() public id?: string;

		protected readonly LeapaItemTypeOptions = LeapaItemTypeOptions;

		beforeMount(): void {
			this.load();
		}

		protected hinted: boolean = false;

		protected async onStartTimeChange(): Promise<void> {
			if(!this.data) return;
			if(this.data.StartTime < this.data.MerchantKiosk.StartTime) {
				this.data.StartTime = this.data.MerchantKiosk.StartTime;
				if(!this.hinted) {
					this.hinted = true; // 訊息提示一次就夠了
					await this.alert("首次編輯時間不可早於設備綁定時間");
				}
			}
		}

		protected get title(): string {
			return this.id ? "編輯貨品" : "設備換貨";
		}

		private async load() {
			this.data = null;
			try {
				this.data = await post<KioskItemEditModel>("/api/Item/GetEditModel", { Id: this.id, MerchantKioskId: this.mkId });
				if(this.data.Price == 0) this.data.Price = null!;
			} catch(e) {
				this.data = null;
				throw e;
			}
		}

		protected async send(): Promise<void> {
			if(!this.data) return;
			this.data.StartTime.setSeconds(0, 0);
			while(this.data.StartTime < this.data.MerchantKiosk.StartTime) {
				this.data.StartTime.setMinutes(this.data.StartTime.getMinutes() + 1);
			}
			var data = {
				MerchantKioskId: this.data.MerchantKiosk.Id,
				Id: this.data.Id,
				LeapaItemType: this.data.LeapaItemType,
				StartTime: this.data.StartTime.toIsoTime(),
				Name: this.data.Name,
				Price: this.data.Price,
				Photo: this.data.Photo,
			};
			await post<boolean>("/api/Item/Edit", data, TEN_SECONDS);
			resetState();
			reportIndexReset();
			this.$router.back();
		}
	}

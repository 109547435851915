
	/* eslint-disable @typescript-eslint/no-magic-numbers */

	import { Component, Prop, Vue } from "vue-property-decorator";
	import type { KioskItemIndexModel } from "@/models/Item";

	@Component
	export default class extends Vue {
		@Prop() public data!: KioskItemIndexModel;

		protected get play(): number {
			return this.data.Report.Total;
		}

		protected get price(): number | null {
			return this.data.KioskItem?.Item.PurchasePrice ?? null;
		}

		protected get cost(): number | null {
			return this.price === null ? null : this.price * this.data.Report.Giftout;
		}

		protected get profit(): string {
			if(this.cost === null) return "未知";
			return (this.play * 10 - this.cost).toComma();
		}

		protected get averagePrice(): number | null {
			const giftout = this.data.Report.Giftout;
			if(this.price === null || giftout == 0) return null;
			return this.play * 10 / giftout;
		}

		protected get averageProfit(): number | null {
			if(this.averagePrice === null) return null;
			return this.averagePrice - this.price!;
		}

		protected get profitRate(): number | null {
			if(this.averageProfit === null) return null;
			return 100 * this.averageProfit / (this.averageProfit + this.price!);
		}
	}

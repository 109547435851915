
	import { Component, Vue } from "vue-property-decorator";

	import { now } from "@common/date";
	import FilterModal from "@/components/modal/FilterModal.vue";
	import { setScroll } from "@/views/setScroll";
	import { toIndexQuery, newQuery } from "@/models/Report";
	import Base from "../Base";
	import Kiosk from "./components/Kiosk.vue";

	import type { Query } from "@/models/Report";
	import type { KioskItemIndexModel } from "@/models/Item";
	import type { TagModel } from "@common/models/kiosk";
	import type { NavigationGuardNext, Route } from "vue-router";

	let cache: { query: Query, state?: { scroll: number, data: KioskItemIndexModel[], time: string } } | null = null;

	export function resetState(): void {
		if(cache) cache.state = undefined;
	}

	export function resetCache(): void {
		cache = null;
	}

	const TIMEOUT = 120000;

	@Component({ components: { Kiosk, FilterModal } })
	export default class extends Base {
		$refs!: {
			filter: FilterModal,
		};

		protected data: KioskItemIndexModel[] | null = null;
		protected tags: TagModel[] = [];
		protected query: Query = newQuery();

		beforeMount(): void {
			post<TagModel[]>('/api/tag').then(v => this.tags = v);
			let scroll = 0;
			if(cache) {
				this.query = cache.query;
				if(cache.state) {
					scroll = cache.state.scroll;
					this.data = cache.state.data;
					this.time = cache.state.time;
					Vue.nextTick(() => setScroll(scroll));
				} else {
					this.load();
				}
				resetCache();
			} else {
				this.load();
			}
		}

		beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
			if(to.path.startsWith('/item') || to.path.startsWith('/report/detail/')) {
				cache = {
					query: this.query,
					state: {
						time: this.time!,
						data: this.data!,
						scroll: document.querySelector('main')!.scrollTop,
					},
				};
			} else {
				resetCache();
			}
			next();
		}

		private async load() {
			this.data = null;
			try {
				const query = toIndexQuery(this.query);
				delete query.Start; // 只檢視當前商品
				this.data = await post<KioskItemIndexModel[]>("/api/Item", query, TIMEOUT);
				this.update = now();
			} catch(e) {
				this.data = null;
				throw e;
			}
		}

		protected async showFilter(): Promise<void> {
			if(await this.$refs.filter.show()) await this.load();
		}
	}
